import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import SectionGrid3 from "../components/sections/grid/grid3"
import HeroInner2 from "../components/sections/hero/inner2"
import Product2Card from "../components/cards/product2"
import useTopCategories from "../hooks/useTopCategories"
import SectionTopCategories from "../components/sections/top-categories"
import SectionBase from "../components/sections/base"
import { Title } from "../components/atoms/titles"
import { Html } from "../components/atoms/text"

const IndexPage = ({ data: { node, products } }) => {

  return (
    <Layout>
      <Hero data={node.relationships.hero} />
      <Section1 />
      <Section3 data={node.relationships.info} />
      <Section2 data={products.nodes} title={node.section_title} />
    </Layout>
  )
}


const Hero = ({ data }) => {


  const { title, subtitle } = data
  const url = data.relationships.image.localFile.publicURL

  const action1 = data.relationships.actions[0]
  //const action2 = data.relationships.actions[1]

  //const backgrounds  = [data.relationships.image.localFile.publicURL]


  let backgrounds = []
  backgrounds = data.relationships.images.map( (m) => ( m.localFile.publicURL  ))
  if( backgrounds.length === 0 ){
    backgrounds.push(url)
  }



  return (
    <HeroInner2
      title={title}
      //style={{ backgroundImage: `url("${url}")` }}
      description={subtitle}
      action1={action1}
      backgrounds={backgrounds}
    />
  )

}


const Section1 = () => {

  const items = useTopCategories()

  return (
    <SectionTopCategories
      items={items}
      className="relative lg:top-[-150px] lg:bottom-[-150px] lg:mb-[-150px] z-10"
    />
  )

}

const Section2 = ({ data, title }) => {

  const cards = data.map((product, index) => (
    <Product2Card
    className="p-0 "
      key={index}
      image={{
        url: product?.relationships?.image?.localFile?.publicURL,
        alt: product?.image?.alt
      }}
      title={product.title}
      html={product?.relationships?.info?.description[0]?.processed}
      url={product?.path?.alias}
    />
  ))

  return (
    <SectionGrid3
      className="py-8 px-0 lg:px-0 lg:py-0 space-y-12 bg-white "
      classNameTitle="text-3xl lg:text-6xl text-center bg-dark text-white py-6 "
      classNameGrid=" gap-x-16 gap-y-0 lg:gap-y-0 lg:mx-32 grid-cols-1 "
      title={title}
    >
      {cards}
    </SectionGrid3>
  )

}

const Section3 = ({ data }) => {

  //console.log(data)

  const { title, description } = data

  return (
    <SectionBase
      className="py-10 px-6 lg:px-36 bg-dark text-white  "

    >

      <Title className="text-3xl lg:text-[40px] font-bold leading-8 mb-6 ">{title}</Title>
      <Html className="text-lg user-content">{description[0].processed}</Html>

    </SectionBase>

  )

}


export default IndexPage


export const query = graphql`
  query products($id: String!) {
    node: nodeProducts(id: {eq: $id }) {
      id
      title
      metatag {
        attributes {
        content
          name
        }
        tag
      }
      path{
        alias
      }
      section_title: field_section_title
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        info: field_paragraph_block {
          ...BlockContent
        }
      }
    }
    products: allNodeProduct {
      nodes {
        title
        status
        path {
          alias
        }
        image: field_image {
          alt
        }
        relationships {
          info: field_paragraph_block {
            ...BlockContent
          }
          image: field_image {
            ...Image
          }
        }
      }
    }
  }
`
